import Vue from 'vue';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import {
	getArticleJsonLdContent,
	getAuthorJsonLdContent,
	getProfileJsonLdContent,
	getJsonLdContent,
	getNewJsonLdContent,
	getPopularJsonLdContent,
} from '@/helpers/json-ld-helper';

import { RTL_LANGUAGES } from '@/constants/rtl-languages';
import { COUNTRY_EXCLUDED, COUNTRY_POPULAR_PAGES_WITH_NOINDEX } from '@/constants/web-locales.constant';
import { AppDirection } from '@/enums/app-direction';
import { CollectionType } from '@/enums/collection-type';
import { createThumborFilter } from '@/filters/thumbor';
import type { Locale } from '@/helpers/locale-helper';

import { NewTitlesEdge } from '@/@types/graphql-types';
import { calculateUrl } from '@/helpers/state-helper';
import { getVm } from '@/helpers/vm-helper';
import type { TitleDetail } from '@/interfaces/title-details-graphql';
import { ArticleFragment, AuthorDataFragment } from '@/pages/graphql/queries/GetArticleByUrl.query';
import type { ProfileNode } from '@/features/user-profile/types';
import { GetPopularTitlesQuery } from '@/pages/graphql/queries/GetPopularTitles.query';
import VueRouter from 'vue-router';
import { RoutingState } from './routing.store';
import { hasUCConsentBanner } from '@/helpers/tracking';

const FONTS_RESOURCES = ['lato-regular.woff2', 'lato-700.woff2', 'lato-900.woff2', 'anton-regular.woff2'];

const state = () => ({
	base: {
		title: 'JustWatch - The Streaming Guide',
		description: 'All your streaming services in one app.',
		meta: [
			{ charset: 'utf-8' },
			{ httpEquiv: 'X-UA-Compatible', content: 'IE=edge' },
			{
				name: 'viewport',
				content:
					'viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no',
			},
			// facebook OG
			{ property: 'og:site_name', content: 'JustWatch' },
			{ property: 'fb:app_id', content: '794243977319785' },
			{ vmid: 'og:image', property: 'og:image', content: `/${ASSETS_DIR}/img/JustWatch_logo_with_claim.png` },
			{ vmid: 'og:image:width', property: 'og:image:width', content: '606' },
			{ vmid: 'og:image:height', property: 'og:image:height', content: '302' },
			{ name: 'mobile-web-app-capable', content: 'yes' },
			{ name: 'apple-mobile-web-app-capable', content: 'yes' },
			{ name: 'theme-color', content: '#182531' },
			// verifications
			{ name: 'google-site-verification', content: 'AkO0-dINGfhSod2X6LeYIp9hwZc8ShczjzJA2a47dME' },
			{ name: 'facebook-domain-verification', content: 'd61y4roe8t5xwbb0gfu6gwe8j8iq3i' },
			{ name: 'dailymotion-domain-verification', content: 'dmorpwc4h544ct6mv' },
			{ name: 'dailymotion-domain-verification', content: 'dmm2vwzsugkzg13q5' },
		],
		link: [],
		htmlAttrs: {
			dir: AppDirection.LTR,
		},
	},
	jsonLd: null,
	pagination: null,
});

export type MetaState = ReturnType<typeof state>;

// GETTERS
const getters: GetterTree<MetaState, any> = {
	head(state, getters, rootState, rootGetters) {
		const urlMetadata = rootGetters['routing/urlMetadata'];
		const routing = <RoutingState>rootState.routing;

		/* When tab title starts with LTR direction word (in most cases it is - JustWatch or Movie Name),
		browser will automatically display whole title with LTR direction. When RTL language chosen we will force
		browser to display tab title (also meta description) in RTL direction by adding hidden RTL unicode character */
		const rtlUnicode = getters.appDirection === AppDirection.RTL ? '\u202b' : '';

		const title = rtlUnicode + (urlMetadata?.meta_title || state.base.title);
		const description = rtlUnicode + (urlMetadata?.meta_description || state.base.description);
		const keywords = urlMetadata?.meta_keywords || '';
		let canonicalUrl = urlMetadata?.full_path ?? routing.activeRoute?.fullPath.split('?')[0];

		const isAppdev = JW_CONFIG.DOMAIN.startsWith('prod-content') || JW_CONFIG.DOMAIN.startsWith('moviecycle');
		const url =
			process.env.NODE_ENV === 'development'
				? `http://localhost:8080${canonicalUrl}`
				: `https://${isAppdev ? '' : 'www.'}${JW_CONFIG.DOMAIN}${canonicalUrl}`;

		const { paginationMetas, paginationCanonical } = getters.pagination;

		const meta = [
			{ vmid: 'og:title', property: 'og:title', content: title },
			// keywords
			{ name: 'keywords', content: keywords },
			// description
			{ vmid: 'description', name: 'description', content: description },
			{
				vmid: 'og:description',
				property: 'og:description',
				content: description,
			},
			{ vmid: 'og:url', property: 'og:url', content: url },
			// pages with `page=2` and higher param should
			...paginationMetas,
		] as any;

		if (urlMetadata?.meta_robots) {
			meta.push({ vmid: 'robots', name: 'robots', content: urlMetadata.meta_robots });
		}

		const isComingSoon = routing.activeRoute?.name?.includes('coming-soon');
		const isUpcomingCountry = ['de', 'es', 'uk', 'us', 'fr'].includes(rootState.language.webLocale);
		if (isComingSoon && isUpcomingCountry) {
			canonicalUrl = calculateUrl(
				getVm()?.$router as VueRouter,
				undefined,
				CollectionType.UPCOMING,
				rootGetters['filter/currentFilters'],
				rootGetters['constant/providersByShortName'],
				rootGetters['constant/genresByShortName'],
				rootGetters['constant/subgenresList']
			);
		}

		// Temporary hacky solution to add the hreflang tags to Home. In SPA they are manually set for home.
		// Once the values are added to `urlMetadata.href_lang_tags`, this can be removed (imports too!).
		if (routing.activeRoute?.name === 'app.home' && urlMetadata.href_lang_tags == null) {
			// language.locales is usually on-demand only, but it's requested for app.home pages on src/routing/index.ts:31
			urlMetadata.href_lang_tags = ((rootState.language.locales || []) as Locale[])
				.filter(locale => !COUNTRY_EXCLUDED[locale.webLocale])
				.filter(locale => !COUNTRY_POPULAR_PAGES_WITH_NOINDEX.includes(locale.webLocale))
				.map(locale => ({
					href: `/${locale.webLocale}`,
					href_lang: `${locale.full_locale}`,
				}));
		}

		// add hreflang x-default tag to home and popular page.
		// special case: if popular page contains robots noindex, then don't display x-default hreflang tag.
		if (['app.home', 'app.titles.popular.list'].includes(routing.activeRoute?.name || '')) {
			const { meta_robots = '' } = urlMetadata;
			const hasNoIndex = meta_robots.includes('noindex');
			const xDefault = !hasNoIndex ? [{ href: '/', href_lang: 'x-default' }] : [];
			urlMetadata.href_lang_tags = [...(urlMetadata.href_lang_tags || []), ...xDefault];
		}

		const script = state.jsonLd
			? [
					{
						type: 'application/ld+json',
						json: state.jsonLd,
					},
			  ]
			: [];

		const link = [
			// improve overall head getter performance
			// static links
			...getters.link,
			// links that change at every page change
			paginationCanonical ?? {
				vmid: 'canonical',
				rel: 'canonical',
				href: `https://www.${JW_CONFIG.DOMAIN}${canonicalUrl}`,
			},
			...(urlMetadata.href_lang_tags || []).map((tag: { href: string; href_lang: string }) => {
				return {
					rel: 'alternate',
					href: `https://www.${JW_CONFIG.DOMAIN}${tag.href}`,
					hreflang: tag.href_lang.replace('_', '-'),
				};
			}),
		];

		return {
			...state.base,
			title,
			meta: state.base.meta.concat(meta),
			link,
			script,
		};
	},
	link(state, _, rootState) {
		// explanation what dns-prefetch & preconnect do and when to use.
		// dns-prefetch is used for resources that might load at some point later.
		// preconnect is used for critical resources that are 100% needed.
		//
		// dns-prefetch:
		// - DNS Lookup (Resolves domain to IP address; 20-150ms)
		//
		// preconnect:
		// - DNS Lookup (Resolves domain to IP address; 20-150ms)
		// - TCP Handshake (Establishes connection to server; 50-150ms)
		// - TLS Negotiation (Establishes a secure connection, if HTTPS; 100-300ms)

		const pageDnsPrefetch: any[] = [];
		if (hasUCConsentBanner(rootState.language.webLocale)) {
			// do not add dns-prefetch if UC won't be loaded anyway
			pageDnsPrefetch.push({ rel: 'dns-prefetch', href: 'https://app.usercentrics.eu' });
			pageDnsPrefetch.push({ rel: 'dns-prefetch', href: 'https://graphql.usercentrics.eu' });
			pageDnsPrefetch.push({ rel: 'dns-prefetch', href: 'https://api.usercentrics.eu' });
			pageDnsPrefetch.push({ rel: 'dns-prefetch', href: 'https://consents.usercentrics.eu' });
		}

		let link = [
			...state.base?.link,
			...pageDnsPrefetch,
			{ rel: 'preconnect', href: 'https://images.justwatch.com' },
			// PRELOADS: fonts
			...FONTS_RESOURCES.filter(
				font =>
					// Anton-Regular isn't used on popular and new, therefore don't preload it
					!(
						font.includes('anton-regular') &&
						['popular', 'new'].includes(rootState.routing.activeRoute?.meta?.collectionType)
					)
			).map(font => ({
				rel: 'preload',
				href: `/${ASSETS_DIR}/fonts/${font}`,
				as: 'font',
				type: 'font/woff2',
				crossorigin: true,
			})),

			// Favicon
			{ rel: 'icon', href: `/${ASSETS_DIR}/favicon.ico` },
		];

		if (BUILD_CONFIG.globals.BUILD_TARGET === 'SSR') {
			link = [
				...link,
				// HTTP PRECONNECT
				...global.HTTP_PRECONNECT,
				// PDNS_PREFETCH
				...global.DNS_PREFETCH,
			];
		}
		return link;
	},
	appDirection(state) {
		return state.base.htmlAttrs.dir;
	},

	// we have different pagination limits based on levels:
	// - level 0: no pagination
	// - level 1: pagination enabled up to ?page=2
	// - level 2: pagination enabled up to ?page=6
	paginationLevel(_, __, rootState, rootGetters) {
		const isLoggedIn = rootGetters['user/isLoggedIn'];
		if (!isLoggedIn) {
			// PAGINATION: LEVEL 2
			if (
				// prettier-ignore
				['us', 'uk', 'ca', 'au', 'de', 'es', 'fr', 'in', 'mx'].includes(
					rootState.language.webLocale
				)
			) {
				return 2;
			}

			// PAGINATION: LEVEL 1
			if (
				// prettier-ignore
				['br', 'it', 'cz', 'hu'].includes(
					rootState.language.webLocale
				)
			) {
				return 1;
			}
		}

		// pagination disabled
		return 0;
	},

	// it will set noindex for any page whose page is >= 2.
	pagination(_, getters, rootState, rootGetters) {
		const isEnabledOnPage = [CollectionType.POPULAR].includes(rootGetters['routing/activeCollectionType']);
		// google's end of november's pagination guide says to not use noindex for page param pages.
		// so we're first testing this behaviour only in 1 country.
		// update 2024-01-04: now all pagination countries support the november pagination guide
		const hasPagination = getters.paginationLevel > 0;
		if (!hasPagination || !isEnabledOnPage) {
			return { paginationMetas: [] };
		}
		const routing = <RoutingState>rootState.routing;
		const { page, exclude_providers, exclude_genres, ...restQuery } = routing.activeRoute?.query as Record<
			string,
			string
		>;
		void exclude_providers;
		void exclude_genres;

		const hasFilteredQueryParams = Object.keys(restQuery).length >= 1;
		const pageNumber = page && !isNaN(page as any) && Number(page) ? parseInt(page) : 1;
		// check if `noindex, follow` needs to be set for page > 1 (pre-november pagination guide)
		const robots =
			hasPagination && hasFilteredQueryParams
				? { vmid: 'robots', name: 'robots', content: 'noindex, follow' }
				: undefined;
		// make sure paginated pages have a canonical to themselves with the page query param.

		const paginationMetas = [robots].filter(Boolean);
		const urlMetadata = rootGetters['routing/urlMetadata'];
		const paginationCanonical =
			hasPagination && !hasFilteredQueryParams && pageNumber > 1
				? {
						vmid: 'canonical',
						rel: 'canonical',
						href: `https://www.${JW_CONFIG.DOMAIN}${urlMetadata?.full_path}?page=${pageNumber}`,
				  }
				: null;
		return { paginationMetas, paginationCanonical };
	},
};

// ACTIONS
const actions: ActionTree<MetaState, any> = {
	resetJsonLD({ state }) {
		Vue.set(state, 'jsonLd', null);
	},

	setTitleJsonLD({ commit, rootGetters }, { title }: { title: TitleDetail }) {
		const genres = rootGetters['constant/genresByShortName'];
		const thumborFilter = Vue.filter('Thumbor') as ReturnType<typeof createThumborFilter>;

		const jsonLdContent = getJsonLdContent(title, genres, thumborFilter);
		commit('SET_JSON_LD', jsonLdContent);
	},

	setArticleJsonLD({ commit, rootGetters }, article: ArticleFragment) {
		commit('SET_JSON_LD', getArticleJsonLdContent(article, rootGetters));
	},

	setAuthorJsonLD({ commit }, author: AuthorDataFragment) {
		commit('SET_JSON_LD', getAuthorJsonLdContent(author));
	},

	setProfileJsonLD({ commit }, profile: ProfileNode) {
		commit('SET_JSON_LD', getProfileJsonLdContent(profile));
	},

	setPopularPageJsonLD({ commit }, popularPageData: GetPopularTitlesQuery['popularTitles']) {
		commit('SET_JSON_LD', getPopularJsonLdContent(popularPageData));
	},

	setNewPageJsonLD({ commit }, newPageData: NewTitlesEdge[]) {
		commit('SET_JSON_LD', getNewJsonLdContent(newPageData));
	},

	setPagination({ commit }, paginationData: { prev: number | null; next: number | null; page: number } | null) {
		commit('SET_PAGINATION', paginationData);
	},

	setAppDirection({ commit }, { language }) {
		const htmlAttrs = {
			lang: language,
			dir: RTL_LANGUAGES.includes(language) ? AppDirection.RTL : AppDirection.LTR,
		};

		commit('SET_HTML_ATTRS', htmlAttrs);
	},

	setJsonLd({ commit }, data) {
		commit('SET_JSON_LD', data);
	},

	setVisiblePosters({ commit }, visiblePosters: number) {
		commit('SET_VISIBLE_POSTERS', visiblePosters);
	},
};

// MUTATIONS
const mutations: MutationTree<MetaState> = {
	SET_JSON_LD(state, jsonLdContent: any) {
		Vue.set(state, 'jsonLd', jsonLdContent);
	},
	SET_HTML_ATTRS(state, htmlAttrs: any) {
		Vue.set(state.base, 'htmlAttrs', {
			...state.base.htmlAttrs,
			...htmlAttrs,
		});
	},
	SET_PAGINATION(state, paginationData: any) {
		Vue.set(state, 'pagination', paginationData);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
