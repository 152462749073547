import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=25b6aded"
import script from "./AppHeader.vue?vue&type=script&setup=true&lang=ts"
export * from "./AppHeader.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./AppHeader.vue?vue&type=style&index=0&id=25b6aded&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports