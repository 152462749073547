//* STORES *//
// TODO: autocomplete possible values for state, getters, and actions based on given namespace and Vuex store
export const useLanguageStore = () =>
	useStore('language', {
		state: ['webLocale', 'locales', 'detectedWebLocale', 'audioLanguage'],
		getters: [
			'language',
			'country',
			'isTTBLanguage',
			'countryEmoji',
			'locale',
			'activeLocalesList',
			'localeObject',
		],
		actions: ['setWebLocale', 'loadLocales', 'setLanguageWhenUserDidNot'],
	});

export const useUserStore = () =>
	useStore('user', {
		state: [
			'preferredExperiments',
			'isPremium',
			'jwId',
			'timeZone',
			'isUserFirstVisit',
			'onboardingStatus',
			'settings',
			'loginProgress',
			'persistedOverriddenLanguage',
			'jwLoginId',
			'loggedInProviders',
			'lastTimeMobileAppBannerWasClosedTS',
			'lastTimeListsOnboardingWasShownTS',
			'userRole',
		],
		getters: [
			'buyboxPresentationType',
			'newBuyboxFilterType',
			'newBuyboxTooltipAcknowledged',
			'isLoggedIn',
			'newBuyboxView',
			'isUserOnboarded',
			'displayHomeSportsModule',
			'settingsProvider',
			'isHideSeenDislikedTitlesEnabled',
			'consents',
			'strictlyAllConsentsGranted',
			'userSearches',
			'isAdmin',
			'isEditor',
			'userRole',
		],
		actions: [
			'setBuyboxPresentationType',
			'setNewBuyboxFilterType',
			'setNewBuyboxTooltipAcknowledged',
			'setPremiumStatus',
			'setPremiumPurchaseFlowState',
			'setNewBuyboxView',
			'setLastTimeListOnboardingWasShownTS',
			'hideHomeSportsModule',
			'setLoginProgress',
			'logout',
			'setPermanentAudiencesSubgenres',
			'resetPermanentAudiencesSubgenres',
			'saveConsents',
			'saveSettings',
			'updateUserVisitStatus',
			'saveSearchTerm',
			'saveVisitedTitleId',
			'setLastTimeMobileAppBannerWasClosedTS',
			'loadSettings',
			'clearSearchHistory',
			'setUserRole',
		],
	});

export const useRoutingStore = () =>
	useStore('routing', {
		state: ['activeRoute', 'urlMetadataPromise', 'lastRoute', 'lastActiveListsCollectionType'],
		getters: ['urlMetadata', 'currentPageType', 'activeCollectionType', 'lastActiveCollectionType', 'queryParams'],
		actions: ['setLastActiveListsCollectionType'],
	});

export const useExperimentStore = () =>
	useStore('experiment', {
		state: ['random'],
		getters: ['activeVariantsWithControlGroup', 'activeVariants'],
		actions: ['registerExperiment', 'triggerExperiment'],
	});

export const useAppStore = () =>
	useStore('app', {
		state: [],
		getters: ['isSeoUser', 'xServeTrack', 'ssrTodaysDate'],
		actions: ['setSSRTodaysDate'],
	});

export const useConstantStore = () =>
	useStore('constant', {
		state: ['ageCertifications', 'genres'],
		getters: [
			'titleProviderCount',
			'providers',
			'allProvidersById',
			'allProvidersByShortName',
			'providersById',
			'sportProvidersById',
			'subgenresList',
			'providersByShortName',
			'genresByShortName',
		],
		actions: ['setUpcomingProviders'],
	});

export const useTitleDetailsStore = () =>
	useStore('titleDetails', {
		state: ['didFetchPromotion'],
		getters: [
			'titleContext',
			'lastVisitedTitleName',
			'lastVisitedTitleId',
			'refferalModuleContext',
			'providerPromotion',
		],
		actions: ['setSponsoredRecommendation', 'setProviderPromotion'],
	});

export const useSportsStore = () =>
	useStore('sports', {
		state: ['didFetchPromotion', 'sportsPromotion'],
		getters: ['sportsPromotion'],
		actions: ['setSportsPromotion'],
	});

export const useFilterStore = () =>
	useStore('filter', {
		state: [],
		getters: [
			'currentFilters',
			'filters',
			'selectedProvidersType',
			'selectedProvidersByType',
			'selectedProviders',
			'editorial',
		],
		actions: ['toggle', 'setFilterValue', 'reset', 'resetFilters', 'setSorting'],
	});

export const useStatisticStore = () =>
	useStore('statistic', {
		state: [],
		getters: ['netflixStatsData'],
		actions: ['setNetflixStatsData'],
	});

export const useMetaStore = () =>
	useStore('meta', {
		state: [],
		getters: ['appDirection', 'paginationLevel', 'head'],
		actions: [
			'setPagination',
			'setArticleJsonLD',
			'setAuthorJsonLD',
			'setPopularPageJsonLD',
			'setNewPageJsonLD',
			'setVisiblePosters',
			'setProfileJsonLD',
			'setJsonLd',
		],
	});

export const useReadyStore = () =>
	useStore('ready', {
		state: ['experimentsLoaded'],
		getters: [],
		actions: ['setReady'],
	});

export const useFooterStore = () =>
	useStore('footer', {
		state: ['topTitles', 'topArticles'],
		getters: [],
		actions: [],
	});

export const useSearchSuggesterStore = () =>
	useStore('searchSuggester', {
		state: [],
		getters: ['isSearchSuggesterOpen', 'query'],
		actions: ['setSearchSuggesterVisibility', 'search', 'reset', 'goToSearch'],
	});

export const useTrackingStore = () =>
	useStore('tracking', {
		state: ['pagesVisited'],
		getters: [],
		actions: ['setSessionEnvironmentByKey', 'setSessionEnvironment'],
	});

export const useListSortingStore = () =>
	useStore('listSorting', {
		state: [],
		getters: ['selectedItem', 'sortingItems'],
		actions: ['setSorting'],
	});

export const useModuleStore = () =>
	useStore('module', {
		state: [],
		getters: [],
		actions: ['addMoreLikeThis', 'setActiveModuleIndex', 'setInteractedModuleIndex'],
	});

export const useModalStore = () =>
	useStore('modal', {
		state: [],
		getters: ['isOpen'],
		actions: ['setOpen'],
	});

//* UTILITIES *//
import type { ComputedRef } from 'vue';
import type { Prettify } from '@/helpers/types';

import { getVm } from '@/helpers/vm-helper';
import { computed, getCurrentInstance } from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';

interface StoreProperties<StateKey extends string, GetterKey extends string, ActionKey extends string> {
	state: ReadonlyArray<StateKey>;
	getters: ReadonlyArray<GetterKey>;
	actions: ReadonlyArray<ActionKey>;
}

/*
For the State type, we use computed instead of ref to avoid losing reactivity.
This can happen with how Vuex and the app sometimes updates state (changing country for example.)
*/
type State<StateKey extends string> = Record<StateKey, ComputedRef<any>>;
type Getters<GetterKey extends string> = Record<GetterKey, ComputedRef<any>>;
type Actions<ActionKey extends string> = Record<ActionKey, (...args: any) => Promise<any>>;

export function useStore<StateKey extends string, GetterKey extends string, ActionKey extends string>(
	namespace: string,
	{ state, getters, actions }: StoreProperties<StateKey, GetterKey, ActionKey>
) {
	const { proxy: instance } = getCurrentInstance() ?? { proxy: getVm() };

	const mappedState = mapState(namespace, state as StateKey[]);
	const mappedGetters = mapGetters(namespace, getters as GetterKey[]);
	const mappedActions = mapActions(namespace, actions as ActionKey[]);

	return Object.assign(
		Object.fromEntries(state.map(key => [key, computed(() => mappedState[key].call(instance))])),
		Object.fromEntries(getters.map(key => [key, computed(() => mappedGetters[key].call(instance))])),
		Object.fromEntries(actions.map(key => [key, (...args: any) => mappedActions[key].apply(instance, args)]))
	) as Prettify<State<StateKey> & Getters<GetterKey> & Actions<ActionKey>>;
}
