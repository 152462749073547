import { ClickoutPlacement, ClickoutType } from '@/interfaces/snowplow/clickout-context';
import { MaybeRefOrGetter, toValue } from '@vueuse/core';
import { inject, provide } from 'vue';
import { InjectionKey } from 'vue';

// additional keys
export const OFFER_ENTITYID_KEY = Symbol() as InjectionKey<string>;

export function useProvideOfferEntityId(entityId: MaybeRefOrGetter<string | undefined>) {
	if (entityId) {
		provide(OFFER_ENTITYID_KEY, toValue(entityId));
	}
}

//clickout placement
export const CLICKOUT_PLACEMENT_KEY = Symbol() as InjectionKey<ClickoutPlacement>;

export function useProvideClickoutPlacement(clickoutPlacement: MaybeRefOrGetter<ClickoutPlacement | undefined>) {
	if (clickoutPlacement) {
		provide(CLICKOUT_PLACEMENT_KEY, toValue(clickoutPlacement));
	}
}

export function getClickoutTrackingPlacement(clickoutType?: ClickoutType) {
	const clickoutPlacement = inject<ClickoutPlacement | undefined>(CLICKOUT_PLACEMENT_KEY, undefined);

	return {
		placement: clickoutPlacement,
		clickoutType: clickoutType,
	};
}
