import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import { type DiscoveryModule } from '@/@types/graphql-types';
import type { Module as ModuleInterface } from '@/interfaces/modules';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';

import { SnowplowModuleContext } from '@/helpers/tracking/providers/snowplow-contexts';
import { GetTitleContextArguments } from '@/helpers/tracking/tracking-helper';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { TitleDetail } from '@/interfaces/title-details-graphql';
import { getPromoText, PromoTextWithIcon } from '@/helpers/provider-promotion';

const INITIAL_STATE = {
	permanentAudiences: [] as string[],
	moduleContext: null as SnowplowModuleContext | null,
	titleContextArguments: null as GetTitleContextArguments | null,
	sponsoredAd: undefined as SponsoredAdFragment | undefined,
	lastVisitedTitleId: '' as string,
	lastVisitedTitleName: '' as string,
	lastVisitedTitleDetails: {
		titleId: '',
		titleName: '',
		isTitleUpcoming: false,
		offers: [],
		fullPath: '',
	} as LastVistedTitleDetails,
	didFetchPromotion: false,
	providerPromotion: null as PromoTextWithIcon | null,
};

export type TitleDetailState = typeof INITIAL_STATE;

export type LastVistedTitleDetails = {
	titleId: string;
	titleName: string;
	isTitleUpcoming?: boolean;
	offers?: TitleDetail['offers'];
	fullPath?: string;
};

const state = () => {
	return {
		...INITIAL_STATE,
	};
};

type State = ReturnType<typeof state>;

const getters: GetterTree<State, any> = {
	sponsoredAd(state): SponsoredAdFragment | undefined {
		return state.sponsoredAd;
	},

	refferalModuleContext(state): SnowplowModuleContext | null {
		return state.moduleContext;
	},

	titleContext(state): GetTitleContextArguments | null {
		return state.titleContextArguments;
	},
	getPermanentAudiences(state): string[] {
		return state.permanentAudiences;
	},

	lastVisitedTitleId(state): string {
		return state.lastVisitedTitleDetails.titleId;
	},
	lastVisitedTitleName(state): string {
		return state.lastVisitedTitleDetails.titleName;
	},
	providerPromotion(state) {
		return state.providerPromotion;
	},
};

const actions: ActionTree<State, any> = {
	setSponsoredRecommendation({ commit }, sponsoredAd: SponsoredAdFragment | null) {
		commit('SET_SPONSORED_RECOMMENDATION', sponsoredAd);
	},
	setModuleContext({ commit }, { module, moduleIndex }: { module: ModuleInterface; moduleIndex: number }) {
		commit('SET_MODULE_CONTEXT', { module, moduleIndex });
	},
	clearModuleContext({ commit }) {
		commit('CLEAR_MODULE_CONTEXT');
	},
	setTitleContext({ commit }, payload: GetTitleContextArguments) {
		commit('SET_TITLE_CONTEXT', payload);
	},
	clearTitleContext({ commit }) {
		commit('CLEAR_TITLE_CONTEXT');
	},
	setPermanentAudiences({ commit }, audiences: string[] = []) {
		commit('SET_PERMANENT_AUDIENCES', audiences);
		TrackingHelper.refreshPermanentAudiences(audiences);
	},
	resetPermanentAudiences({ commit }) {
		commit('SET_PERMANENT_AUDIENCES', []);
	},
	setLastVisitedTitleDetails({ commit }, lastVisitedTitleDetails: LastVistedTitleDetails) {
		commit('SET_LAST_VISITED_TITLE_DETAILS', lastVisitedTitleDetails);
	},
	setProviderPromotion({ commit, rootGetters }, title?: TitleDetail) {
		if (title == null) return;

		const country = rootGetters['language/country'];
		const providerPromotion = getPromoText(country, title);

		commit('SET_PROVIDER_PROMOTION', providerPromotion);
		commit('SET_DID_FETCH_PROMOTION', true);
	},
};

const mutations: MutationTree<State> = {
	SET_SPONSORED_RECOMMENDATION(state, sponsoredAd: SponsoredAdFragment | undefined) {
		state.sponsoredAd = sponsoredAd;
	},
	SET_MODULE_CONTEXT(state, { module, moduleIndex }: { module: DiscoveryModule; moduleIndex: number }) {
		state.moduleContext = new SnowplowModuleContext(
			module?.id ?? '',
			moduleIndex ?? -1,
			module.template.technicalName,
			module.discoverySessionId ?? '',
			module.template?.anchor ?? undefined
		);
	},
	CLEAR_MODULE_CONTEXT(state) {
		state.moduleContext = null;
	},
	SET_TITLE_CONTEXT(state, titleContextArguments: GetTitleContextArguments) {
		state.titleContextArguments = titleContextArguments;
	},
	CLEAR_TITLE_CONTEXT(state) {
		state.titleContextArguments = null;
	},
	SET_PERMANENT_AUDIENCES(state, value) {
		state.permanentAudiences = value;
	},
	SET_LAST_VISITED_TITLE_DETAILS(state, lastVisitedTitleDetails: LastVistedTitleDetails) {
		state.lastVisitedTitleDetails = lastVisitedTitleDetails;
	},
	SET_PROVIDER_PROMOTION(state, providerPromotion: PromoTextWithIcon | null) {
		state.providerPromotion = providerPromotion;
	},
	SET_DID_FETCH_PROMOTION(state, didFetch: boolean) {
		state.didFetchPromotion = didFetch;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<State, any>;
