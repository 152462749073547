import { CollectionType } from '@/enums/collection-type';
import { WebLocale } from '@/enums/web-locale';
import { RouteConfig } from 'vue-router/types/router';
import { PageType } from './config';

/**
 * Generate the app routes for Authors, List Articles, and
 * News Articles.
 *
 * @param currentLocale
 */
export function makeEditorialRoutes(currentLocale: WebLocale) {
	/*
        **IMPORTANT**
        The route order is important, they are evaluated in order so
        watchout for overlapping :dyanmic route segments!
    */

	const routes: RouteConfig[] = [];

	if (isEditorialLocale(currentLocale)) {
		routes.push(makeGuideRoute(currentLocale));
		routes.push(makeEditorialArticleRoute(currentLocale));
	}

	if (isAuthorLocale(currentLocale)) {
		routes.push(makeAuthorRoute(currentLocale));
	}

	return routes;
}

/////////////
/* AUTHORS */
/////////////
type AuthorLocale = keyof typeof authorSlugs;

const authorSlugs = {
	[WebLocale.au]: 'author',
	[WebLocale.br]: 'autor',
	[WebLocale.bs]: 'author',
	[WebLocale.ca]: 'author',
	[WebLocale.de]: 'autor',
	[WebLocale.es]: 'autor',
	[WebLocale.fr]: 'auteur',
	[WebLocale.in]: 'author',
	[WebLocale.it]: 'autore',
	[WebLocale.tr]: 'yazar',
	[WebLocale.uk]: 'author',
	[WebLocale.us]: 'author',

	// [WebLocale.ar]: 'autor',
} as const;

// TODO: when these profiles are not just authors move to own "user" routes folder
export function makeAuthorRoute(currentLocale: AuthorLocale): RouteConfig {
	return {
		path: `/${currentLocale}/${authorSlugs[currentLocale]}/:name`,
		name: 'app.user.author',
		component: () => import(/* webpackChunkName: "userprofile" */ '@/pages/UserProfile.vue'),
		meta: {
			tab: 'user-profile',
			hasFilterBar: false,
			pageType: PageType.USER_PROFILE,
		},
	};
}

function isAuthorLocale(locale: WebLocale): locale is AuthorLocale {
	return locale in authorSlugs;
}

////////////////////
/* GUIDE HOMEPAGE */
////////////////////
export type EditorialLocale = keyof typeof editorialSlugs;

export const editorialSlugs = {
	[WebLocale.br]: 'guia',
	[WebLocale.bs]: 'guide',
	[WebLocale.de]: 'guide',
	[WebLocale.es]: 'guia',
	[WebLocale.fr]: 'guide',
	[WebLocale.in]: 'guide',
	[WebLocale.it]: 'guida',
	[WebLocale.tr]: 'haberler',
	[WebLocale.uk]: 'guide',
	[WebLocale.us]: 'guide',

	// [WebLocale.ar]: 'guia',
	// [WebLocale.au]: 'guide',
	// [WebLocale.ca]: 'guide',
} as const;

export function isEditorialLocale(locale: WebLocale): locale is EditorialLocale {
	return locale in editorialSlugs;
}

export function makeGuideRoute(currentLocale: EditorialLocale): RouteConfig {
	return {
		path: `/${currentLocale}/${editorialSlugs[currentLocale]}`,
		name: 'app.editorial.list',
		component: () => import(/* webpackChunkName: "editorial" */ '@/pages/editorial/GuidePage.vue'),
		meta: { tab: 'editorial', pageType: PageType.EDITORIAL },
	};
}

////////////////////////
/* EDITORIAL ARTICLES */
////////////////////////
function makeEditorialArticleRoute(currentLocale: EditorialLocale): RouteConfig {
	// /{locale}/{guide} -> /de/news/:slug
	return {
		component: () => import(/* webpackChunkName: "editorial" */ '@/pages/editorial/EditorialArticle.vue'),
		name: 'app.editorial.article',
		path: `/${currentLocale}/${editorialSlugs[currentLocale]}/:slug`,
		meta: {
			tab: 'editorial',
			collectionType: CollectionType.EDITORIAL,
			context: ['filters'],
			pageType: PageType.EDITORIAL_ARTICLE,
		},
	};
}

///////////////////
/* EDITORIAL RSS */
///////////////////
export function makeRssMetaInfo() {
	return {
		/*
			INFO: this overwrites the `urlMetadata.meta_robots` in meta.store.ts that might sometimes
			be set with noindex and/or nofollow which we would not set on guide pages anyways.
		*/
		meta: [{ vmid: 'robots', name: 'robots', content: 'max-image-preview:large' }],
		link: Object.keys(editorialSlugs).map(locale => ({
			rel: 'alternate',
			type: 'application/rss+xml',
			href: `https://www.${JW_CONFIG.DOMAIN}/${locale}/rss.xml`,
		})),
	};
}
