import Vue from 'vue';
import { Route } from 'vue-router';
import { ActionTree, MutationTree } from 'vuex';

import { CollectionType } from '@/enums/collection-type';

import { getAxios } from '@/helpers/axios-helper';

import { UrlMetadataResponse } from '@/interfaces/responses/url-metadata';

import { getPageType } from '@/helpers/tracking/tracking-helper';
import { RouterMeta } from '@/routing/config';
import { OnboardingStatus } from '@/enums/onboarding-status';

export type ListsCollectionType =
	| CollectionType.MY_LISTS
	| CollectionType.PUBLIC_LISTS
	| CollectionType.TV_SHOW_TRACKING;

export type RoutingState = {
	activeRoute: Route | null;
	// this is used to fix the lazy loading of components with ion tabs.
	// [tab]: true when activated once
	activatedTabs: Record<string, boolean>;
	lastActiveCollectionType: CollectionType | null;
	lastActiveListsCollectionType: ListsCollectionType | null;
	lastRoute: Route | null;
	lastUrlMetadata: UrlMetadataResponse | null;
	pageType: string;
	scrollPositions: Partial<Record<RouterMeta['tab'], number>>;
	urlMetadata: UrlMetadataResponse | null;
	// TODO We're assuming implicitly this would only be called after promise is registered. That should not be the case.
	// An event that other modules can register to - so they get the data when it's there.
	urlMetadataPromise: Promise<UrlMetadataResponse> | null;
};

type RoutingGetter<T> = (state: RoutingState, getters: any) => T;

export type RoutingGetters = {
	activeCollectionType: RoutingGetter<CollectionType>;
	currentPageType: RoutingGetter<string>;
	lastActiveCollectionType: RoutingGetter<CollectionType | null>;
	lastActiveListsCollectionType: RoutingGetter<ListsCollectionType | null>;
	queryParams: RoutingGetter<Route['query']>;
	urlMetadata: RoutingGetter<RoutingState['urlMetadata']>;
};

export type RoutingStore = {
	state: RoutingState;
	getters: RoutingGetters;
};

const state = () =>
	({
		activatedTabs: {},
		activeRoute: null,
		pageType: 'VIEW_HOME', // default url
		lastActiveCollectionType: null,
		lastActiveListsCollectionType: null,
		lastRoute: null,
		lastUrlMetadata: null,
		scrollPositions: {},
		urlMetadata: null,
		urlMetadataPromise: new Promise(() => {}),
	} as RoutingState);

const getters: RoutingGetters = {
	activeCollectionType(state): CollectionType {
		return state.activeRoute ? (state.activeRoute.meta || {}).collectionType : null;
	},
	currentPageType(state): string {
		return state.pageType;
	},
	lastActiveCollectionType(state, getters): CollectionType | null {
		const currentCollectionType = getters.activeCollectionType;
		if (currentCollectionType) {
			return currentCollectionType;
		}

		const { lastActiveCollectionType } = state;
		if (lastActiveCollectionType) {
			return lastActiveCollectionType;
		}

		// This is a failsafe, it should never land here. Gotta investigate or rewrite this
		return CollectionType.GLOBAL;
	},
	lastActiveListsCollectionType(state): ListsCollectionType | null {
		return state.lastActiveListsCollectionType;
	},
	queryParams(state): Route['query'] {
		if (state.activeRoute) {
			// TODO Unsure if casting makes sense here.
			return state.activeRoute.query as Dictionary<string | string[]>;
		} else {
			return {};
		}
	},
	urlMetadata(state): RoutingState['urlMetadata'] {
		const urlMetadata = (state.urlMetadata || {}) as UrlMetadataResponse;
		const heading_1 = urlMetadata.heading_1;
		return {
			...urlMetadata,
			...{ heading_1 },
		};
	},
};

const actions: ActionTree<RoutingState, any> = {
	async fetchUrlMetadata({ commit, state, rootState }, url: string) {
		let path = url.split('?')[0];
		if (path !== '/' && !path.includes('shared')) {
			path = path.replace(/\/$/, ''); // remove trailing backslash
			try {
				const promise = getAxios()
					.get(`${JW_CONFIG.API}/urls`, {
						params: {
							path,
						},
					})
					.then(({ data }) => data);
				commit('SET_URL_METADATA_PROMISE', promise);
				const urlMetadata = await promise;
				commit('SET_LAST_URL_METADATA', state.urlMetadata);
				commit('SET_URL_METADATA', urlMetadata);
			} catch (err) {
				console.error('[RoutingStore] fetchUrlMetadata', err);
				commit('SET_URL_METADATA_PROMISE', Promise.resolve());
			}

			// @todo netflix 4k exception
			// @todo maybe set customCanonical to meta.store.ts state?
		} else {
			const onboardingStatus = rootState.user.onboardingStatus;
			const technical_name = onboardingStatus === OnboardingStatus.GLOBAL ? 'VIEW_HOME_GLOBAL' : 'VIEW_HOME';

			const homeMockMeta = {
				meta_title: 'JustWatch - The Streaming Guide',
				meta_description: 'All your streaming services in one app.',
				meta_keywords: '',
				page: { technical_name },
			};

			commit('SET_LAST_URL_METADATA', state.urlMetadata);
			commit('SET_URL_METADATA', homeMockMeta);
			commit('SET_URL_METADATA_PROMISE', Promise.resolve());
		}

		commit('SET_PAGE_TYPE', getPageType(state.urlMetadata));
	},

	async setActiveRoute({ commit }, { activeRoute }: { activeRoute: Route }) {
		const meta = { ...activeRoute.meta };

		// exclude 'matched' that causes circular dependency
		/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
		const { matched, ...route } = activeRoute;
		commit('SET_ACTIVE_ROUTE', { ...route, path: decodeURI(route.path), meta });
		commit('ACTIVATE_TAB', activeRoute?.meta?.tab);
	},
	setLastActiveListsCollectionType({ commit }, lastActiveListsCollectionType: ListsCollectionType) {
		commit('SET_LAST_ACTIVE_LISTS_COLLECTION_TYPE', lastActiveListsCollectionType);
	},
};

const mutations: MutationTree<RoutingState> = {
	ACTIVATE_TAB(state, tab: string) {
		if (tab) {
			state.activatedTabs = {
				...state.activatedTabs,
				[tab]: true,
			};
		}
	},
	SET_ACTIVE_ROUTE(state, activeRoute) {
		Vue.set(state, 'lastRoute', { ...state.activeRoute });
		Vue.set(state, 'activeRoute', { ...activeRoute });
		// only set lastActiveCollectionType of the last route that had a collectionType
		if (activeRoute && (activeRoute.meta || {}).collectionType) {
			Vue.set(state, 'lastActiveCollectionType', activeRoute.meta.collectionType);
		}
	},
	SET_LAST_ACTIVE_LISTS_COLLECTION_TYPE(state, lastActiveListsCollectionType: ListsCollectionType) {
		state.lastActiveListsCollectionType = lastActiveListsCollectionType;
	},
	SET_LAST_URL_METADATA(state, lastUrlMetadata) {
		state.lastUrlMetadata = lastUrlMetadata;
	},
	SET_PAGE_TYPE(state, pageType) {
		state.pageType = pageType;
	},
	SET_URL_METADATA(state, urlMetadata) {
		const { meta_robots = '' } = urlMetadata;
		const hasNoIndex = meta_robots.includes('noindex');
		const hrefLangTags = (urlMetadata.href_lang_tags || [])
			// make sure we don't include urls that have noindex
			.filter((tag: any) => !tag.has_noindex && !hasNoIndex)
			.map((tag: any) => ({
				href: tag.href,
				href_lang: tag.href_lang,
				locale: tag.locale,
			}));
		state.urlMetadata = {
			...urlMetadata,
			href_lang_tags: hrefLangTags,
			allHrefLangTags: urlMetadata.href_lang_tags || [],
		};
	},
	SET_URL_METADATA_PROMISE(state, urlMetadataPromise) {
		state.urlMetadataPromise = urlMetadataPromise;
	},
	SET_SCROLL_POSITION(state, { tab, y }: { tab: RouterMeta['tab']; y: number }) {
		if (tab) Vue.set(state, 'scrollPositions', { ...state.scrollPositions, [tab]: y });
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
