
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { AccountModal, SettingsModal } from '@/lazy-load-components';

import { ModalHelper } from '@/helpers/modal-helper';
import BasicButton from '@/ui-components/BasicButton.vue';
import { trackPopularExp } from '../experiments/PopularPageExp/tracking';
// APPLE_PROMO_EXP
import { trackAppleBuyboxPromotionExp } from '@/components/experiments/AppleBuyboxPromotionExp/tracking';
// APPLE_PROMO_EXP

const User = namespace('user');

@Component({
	name: 'LoginMenuButtonNew',
	components: {
		BasicButton,
	},
})
export default class LoginMenuButtonNew extends Vue {
	@Prop({ type: Boolean, default: false }) dark: boolean;

	@User.State loginProgress: boolean;

	@User.Getter isLoggedIn: boolean;
	@User.Mutation SET_LOGIN_PROGRESS: (value: boolean) => void;

	handleAccountClick() {
		ModalHelper.openModal(SettingsModal);

		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		// trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'user_account' });
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX

		// SEASON_PAGE_BUYBOX_EXP
		trackAppleBuyboxPromotionExp({ action: 'click', label: 'user_account' });
		// SEASON_PAGE_BUYBOX_EXP

		//POPULAR_EXP
		trackPopularExp({ action: 'click', label: 'user_account' });
		//POPULAR_EXP
	}

	handleLoginClick() {
		ModalHelper.openModal(AccountModal, { closable: true }, { cssClass: 'login-modal' }, '', false, [], () =>
			this.SET_LOGIN_PROGRESS(false)
		);

		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		// trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'user_account' });
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX

		// APPLE_PROMO_EXP
		trackAppleBuyboxPromotionExp({ action: 'click', label: 'user_account' });
		// APPLE_PROMO_EXP

		//POPULAR_EXP
		trackPopularExp({ action: 'click', label: 'user_account' });
		//POPULAR_EXP
	}
}
