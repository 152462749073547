import { Viewport } from '@/enums/viewport';

let viewport: Viewport = Viewport.SMARTPHONE;
if (process.client) {
	viewport = calculateViewport();
}

export function calculateViewport(width = window.innerWidth): Viewport {
	if (width <= 512) return Viewport.SMARTPHONE;
	else if (width <= 960) return Viewport.TABLET;
	else return Viewport.DESKTOP;
}

function isRetinaSupported() {
	if (!window) {
		return true;
	}
	const matchString =
		'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)';
	return !!(
		(window.matchMedia && window.matchMedia(matchString).matches) ||
		(window.devicePixelRatio && window.devicePixelRatio >= 2)
	);
}

/* PUBLIC */

function calculateViewportPixels(): number {
	return window.innerWidth;
}

function calculateScreenHeight(): number {
	return window.screen.height;
}

function calculateScreenWidth(): number {
	return window.screen.width;
}

let _hasRetinaSupport = true;
if (process.client) {
	_hasRetinaSupport = isRetinaSupported();
}

function hasRetinaSupport() {
	return _hasRetinaSupport;
}

function updateViewport() {
	if (process.client) {
		viewport = calculateViewport();
	}
}

function getViewport() {
	return viewport;
}
function isMobile(): boolean {
	return viewport !== Viewport.DESKTOP;
}

export {
	calculateScreenHeight,
	calculateScreenWidth,
	calculateViewportPixels,
	getViewport,
	hasRetinaSupport,
	isMobile,
	updateViewport,
};
