import { Offer as OfferGraphql, Package } from '@/@types/graphql-types';

export type ClickoutOfferDetails = Pick<
	OfferGraphql,
	| 'standardWebURL'
	| 'monetizationType'
	| 'presentationType'
	| 'retailPriceValue'
	| 'currency'
	| 'lastChangeRetailPriceValue'
> & {
	package: Pick<Package, 'packageId' | 'clearName'>;
	preAffiliatedStandardWebURL?: OfferGraphql['preAffiliatedStandardWebURL'];
};

export type ClickoutPlacement =
	| 'banner_buybox'
	| 'promoted_buybox'
	| 'regular_buybox'
	| 'search_buybox'
	| 'episode_buybox'
	| 'editorial_buybox'
	| 'editorial_pick'
	| 'discovery_top_10_module'
	| 'discovery_classic_module'
	| 'backdrop'
	| 'popular_page'
	| 'spotlight'
	| 'top_10'
	| 'title_grid'
	| 'title_list'
	| 'title_timeline'
	| 'public_list'
	| 'user_list'
	| 'tv_show_tracking'
	| 'sr_home'
	| 'sr_popular'
	| 'sr_title_detail'
	| 'sr_title_detail_similar_titles'
	| 'sr_search_suggester'
	| 'sr_search'
	| 'sr_new';

export type ClickoutType =
	| 'sr_image'
	| 'sr_premium_image'
	| 'sr_video'
	| 'sr_premium_video'
	| 'sr_title_list'
	| 'sr_image_watch_now'
	| 'sr_premium_image_watch_now'
	| 'sr_video_watch_now'
	| 'sr_premium_video_watch_now'
	| 'sr_title_list_watch_now'
	| 'regular'
	| 'promoted'
	| 'free_trial'
	| 'qtv'
	| 'watch_now'
	| 'sponsored_recommendation'
	| 'video_player'
	| 'module'
	| 'offer_purchase_button';

export const isClickoutOfferDetails = (offer: any): offer is ClickoutOfferDetails =>
	offer != null &&
	offer.package?.clearName &&
	offer.package?.packageId &&
	offer.monetizationType &&
	offer.presentationType;

export enum ClickoutContentType {
	MainEvent = 'main-event',
	Highlight = 'highlight',
	Interview = 'interview',
	LeavingSoon = 'leaving-soon',
}
