import { getVm } from './vm-helper';

import {
	MonetizationType,
	ObjectType,
	Offer as OfferGraphql,
	OfferType,
	Package,
	SportObjectType,
} from '@/@types/graphql-types';
import { TitleOfferFragment } from '@/components/buybox/graphql/fragments/Offer.fragment';
import { toRESTMonetizationType, toRESTPresentationType } from '@/helpers/graphql-model-helper';
import {
	SnowplowClickoutContextGraphql,
	SnowplowContext,
	SnowplowTitleContextGraphql,
} from '@/helpers/tracking/providers';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { BuyboxOfferCategory } from '@/interfaces/buybox';
import { ClickoutContentType } from '@/interfaces/snowplow/clickout-context';
import { BuyBoxFilterOptions } from '@/stores/user.store';

const CATEGORY_MONETIZATIONS_GRAPHQL: Record<BuyboxOfferCategory, MonetizationType[] | 'BUNDLE'> = {
	[BuyboxOfferCategory.STREAM]: [
		MonetizationType.Flatrate,
		MonetizationType.Free,
		MonetizationType.Ads,
		MonetizationType.FlatrateAndBuy,
	],
	[BuyboxOfferCategory.RENT]: [MonetizationType.Rent],
	[BuyboxOfferCategory.BUY]: [MonetizationType.Buy],
	[BuyboxOfferCategory.PHYSICAL]: [MonetizationType.Buy],
	[BuyboxOfferCategory.CINEMA]: 'BUNDLE',
	// We need add BUNDLE as Monetization Type (exception) to be able to display bundles in Promoted BuyBox
	[BuyboxOfferCategory.BUNDLE]: 'BUNDLE',
};

export type NarrativeObjectType =
	| ObjectType.Show
	| ObjectType.ShowEpisode
	| ObjectType.ShowSeason
	| ObjectType.Movie
	| ObjectType.Person;

export const enum OfferLocation {
	REGULAR_BUYBOX = 0,
	PROMOTED_BUYBOX = 1,
	BACKDROP = 2,
	MIDDLE_BUYBOX = 3,
	FREE_TRIAL = 4,
	BANNER_BUYBOX = 5,
	EPISODE_BUYBOX = 6,
}

// These strings are checked as is in JW-UCT becareful if changing them
export type BuyboxParam = (typeof BuyboxParam)[keyof typeof BuyboxParam];
export const BuyboxParam = {
	Normal: 'normal',
	Promoted: 'special',
	Sport: 'sports',
} as const;

const getBuyboxCategoryByMonetizationGraphql = (
	monetizationType: MonetizationType
): BuyboxOfferCategory | undefined => {
	return (Object.keys(CATEGORY_MONETIZATIONS_GRAPHQL) as (keyof typeof CATEGORY_MONETIZATIONS_GRAPHQL)[]).find(
		key => CATEGORY_MONETIZATIONS_GRAPHQL[key].indexOf(monetizationType) !== -1
	);
};

const getTranslatableForShowOfferGraphql = (
	offer: TitleOfferFragment | OfferGraphql,
	objectType: ObjectType
): null | string => {
	const vm = getVm();

	if (offer.elementCount) {
		if (objectType === ObjectType.Show) {
			return offer.elementCount > 1
				? vm.$t('WEBAPP_X_SEASONS', { seasons: offer.elementCount })
				: vm.$t('WEBAPP_ONE_SEASON');
		}

		if (objectType === ObjectType.ShowSeason) {
			return offer.elementCount > 1
				? vm.$t('WEBAPP_X_EPISODES', { episodes: offer.elementCount })
				: vm.$t('WEBAPP_ONE_EPISODE');
		}
	}
	return null;
};

const getTranslatableForOfferGraphql = (
	offer: TitleOfferFragment | OfferGraphql,
	objectType: ObjectType
): false | string => {
	const vm = getVm();
	const translatableForShowOfferGraphql = getTranslatableForShowOfferGraphql(offer, objectType);
	if ((offer.type === OfferType.Aggregated || !offer.retailPriceValue) && translatableForShowOfferGraphql) {
		return translatableForShowOfferGraphql;
	}

	return (
		{
			[MonetizationType.Flatrate]: vm.$t('WEBAPP_FLAT'),
			[MonetizationType.FlatrateAndBuy]: vm.$t('WEBAPP_FLAT'),
			[MonetizationType.Ads]: vm.$t('WEBAPP_ADS'),
			[MonetizationType.Free]: vm.$t('WEBAPP_FREE'),
			[MonetizationType.Cinema]: vm.$t('WEBAPP_CINEMA'),
		}[offer.monetizationType as string] ?? false
	);
};

const trackClickOutSports = (
	offer: Pick<
		OfferGraphql,
		'monetizationType' | 'presentationType' | 'retailPriceValue' | 'currency' | 'lastChangeRetailPriceValue'
	> & { package: Pick<Package, 'packageId' | 'clearName' | 'technicalName'> },
	modulePositionInList: number | undefined,
	matchPositionInList: number | undefined,
	sportObjectType: SportObjectType,
	offerLocation: string,
	additionalContexts?: SnowplowContext[]
) => {
	// A bit hacky but for clickouts, we do a double tracking.
	// We track in Snowplow (slow) and directly in the consumer api (real time).
	const contexts: SnowplowContext[] = [
		// clickout context
		SnowplowClickoutContextGraphql.fromProviderOffer(offer, sportObjectType),
		...(additionalContexts ? additionalContexts : []),
		// package context
		TrackingHelper.getPackageContext(offer.package.packageId, offer.package.technicalName),
	];

	TrackingHelper.trackEvent(
		'clickout',
		{
			action: 'sports',
			label: offerLocation,
			property:
				modulePositionInList !== undefined && matchPositionInList !== undefined
					? `${modulePositionInList}_${matchPositionInList}`
					: undefined, // <{position of module in list}__{position of match in list}>
		},
		contexts
	);

	if (offerLocation === 'watchnow') {
		// legacy reasons we need to additionally track watchnow events
		TrackingHelper.trackEvent(
			'userinteraction',
			{
				action: 'watchnow_clicked',
				label: 'sports',
			},
			contexts
		);
	}
};

export {
	getBuyboxCategoryByMonetizationGraphql,
	getTranslatableForShowOfferGraphql,
	getTranslatableForOfferGraphql,
	trackClickOutSports,
};
