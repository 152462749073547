/**
 * collection of snowplow contexts.
 * json schemas: https://gitlab.justwatch.com/jw/jw-redshift/tree/master/iglu-schemas/schemas/com.justwatch
 */

import {
	MultiStepSportEvent,
	ObjectType,
	SingleStepSportEvent,
	SportObjectType,
	SportType,
} from '@/@types/graphql-types';
import { getTitleContextEntityId } from '@/helpers/entity-helper';
import { toRESTMonetizationType, toRESTPresentationType } from '@/helpers/graphql-model-helper';
import { isEmptyArray } from '@/helpers/object-helper';
import { ScoringProvider, SCORINGS } from '@/helpers/scoring-helper';
import {
	ClickoutContentType,
	ClickoutOfferDetails,
	ClickoutPlacement,
	ClickoutType,
} from '@/interfaces/snowplow/clickout-context';
import { TitleContentType } from '@/interfaces/snowplow/title-context';
import { SportPageType } from '@/interfaces/sport-graphql';
import { TitleObjectType } from '@/interfaces/titles';
import { FilterCollection } from '@/stores/filter.store';
import { Route } from 'vue-router';
import type { Metric } from 'web-vitals';
import { BuyBoxFilterOptions } from '@/stores/user.store';
import { SessionEnvironment } from '@/stores/tracking.store';

// looks something like this: 'iglu:com.justwatch/title_context/jsonschema/1-0-0'
export type SnowplowSchema = string;

/**
 * Collection of snowplow context classes that can be created
 *
 * Note: every new Context needs to be added to the CONTEXT_CLASSES object at the end of the file
 */
export abstract class SnowplowContext {
	/**
	 * all SnowplowContexts need to have a __name property for unserialization reasons (server > client)
	 */
	abstract __name: SnowplowContextName;

	toObject() {
		// filter out undefined or null values
		const data = Object.keys(this)
			.filter(key => !key.startsWith('__')) // exclude __name property in output
			.reduce((root: any, key: string) => {
				const value = (this as any)[key];
				if ([undefined, null].indexOf(value) === -1) {
					root[key] = value;
				}
				return root;
			}, {});

		return {
			schema: this.schema,
			data,
		};
	}

	/**
	 * Schema is unique for each context.
	 */
	abstract get schema(): SnowplowSchema;
}

export class SnowplowTitleContext extends SnowplowContext {
	__name = 'TitleContext' as const;
	jwEntityId: string;

	constructor(
		public titleId: number,
		public objectType: string,
		public seasonNumber: number | null = null,
		public episodeNumber: number | null = null
	) {
		super();
		this.jwEntityId = getTitleContextEntityId(
			objectType.toLocaleUpperCase() as ObjectType,
			titleId,
			'SnowplowTitleContext'
		);
	}

	get schema() {
		return 'iglu:com.justwatch/title_context/jsonschema/1-0-0';
	}
}

export class SnowplowTitleContextGraphql extends SnowplowContext {
	__name = 'TitleContextGraphql' as const;
	jwEntityId: string;

	constructor(
		public titleId: number,
		public objectType: ObjectType,
		public seasonNumber: number | null = null,
		public episodeNumber: number | null = null,
		public contentType: TitleContentType | undefined | null = null,
		public index: number | undefined = undefined,
		/** offerEntityId is a temporary additionalProperty to pass the entityId of an offer on clickouts for UCT2. */
		public offerEntityId: string | undefined = undefined
	) {
		super();

		this.objectType = objectType?.toLowerCase() as ObjectType;
		this.jwEntityId = getTitleContextEntityId(objectType, titleId, 'SnowplowTitleContextGraphql');
	}

	get schema() {
		return 'iglu:com.justwatch/title_context/jsonschema/1-2-1';
	}
}

// flex-context 1-0-0 is a generic schema to send valueText, valueInt and valueBool
// this is being used to wrap the user email hash with key e
export class SnowplowHashedLoggedInUserContext extends SnowplowContext {
	__name = 'HashedLoggedInUserContext' as const;
	public key = 'e' as const;
	public valueText: string;

	constructor(hash: string) {
		super();
		this.key;
		this.valueText = hash;
	}

	get schema() {
		return 'iglu:com.justwatch/flex_context/jsonschema/1-0-0';
	}
}

export type SnowplowTitleContextGraphqlPayload = ConstructorParameters<typeof SnowplowTitleContextGraphql>;

export class SnowplowPersonContext extends SnowplowContext {
	__name = 'PersonContext' as const;
	constructor(public personId: number, public fullName: string) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/person_context/jsonschema/1-0-0';
	}
}

export class SnowplowCampaignContextV1 extends SnowplowContext {
	__name = 'CampaignContextV1' as const;
	constructor(
		public network: string,
		public targetUrl: string,
		public targetId: number | null = null,
		public placement: string | null = null,
		public campaignId: number | null = null,
		public adgroupId: number | null = null,
		public creativeId: number | null = null,
		public adId: number | null = null,
		public videoId: number | null = null,
		public keyword: string | null = null,
		public audienceType: string | null = null
	) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/campaign_context/jsonschema/1-0-0';
	}
}

export class SnowplowCampaignContextV2 extends SnowplowContext {
	__name = 'CampaignContextV2' as const;
	constructor(
		public buyer: string, // uct_b
		public supplier: string, // uct_s
		public inventory: string, // uct_i
		public adType: string, // uct_at
		public targetingName: string, // uct_tn
		public targetUrl: string,
		public placement: string | null = null, // uct_p
		public slotId: number | null = null, // uct_sid
		public campaignId: number | null = null, // uct_cid
		public adgroupId: number | null = null, // uct_adgid
		public creativeId: number | null = null, // uct_crid
		public adId: number | null = null, // uct_adid
		public slotSupplierId: number | null = null, // uct_ssid
		public subcampaignId: number | null = null,
		public eccId: number | null = null
	) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/campaign_context/jsonschema/2-0-0';
	}
}

export class SnowplowGeoContext extends SnowplowContext {
	__name = 'GeoContext' as const;
	constructor(
		public latitude: number,
		public longitude: number,
		public locator_type: 'geocoding' | 'geolocation',
		public accuracy: number | null = null
	) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/geo_context/jsonschema/1-0-0';
	}
}

export class SnowplowLoginContext extends SnowplowContext {
	__name = 'LoginContext' as const;
	constructor(public jwLid: string) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/login_context/jsonschema/1-0-0';
	}
}

export class SnowplowSessionEnvironmentContext extends SnowplowContext {
	__name = 'SessionEnvironmentContext' as const;

	public origin: string | null;
	public env: string | null;
	public hasAdblocker: boolean | null;
	public originPageUrl: string | null;
	public originPageReferred: string | null;
	public originRefrMedium: string | null;
	public originRefrSource: string | null;
	public originRefrSocial: string | null;

	constructor(sessionEnvironment: SessionEnvironment) {
		super();
		this.origin = sessionEnvironment.origin;
		this.hasAdblocker = sessionEnvironment.hasAdBlocker;
		this.originPageUrl = sessionEnvironment.originPageUrl;
		this.originPageReferred = sessionEnvironment.originPageReferred;
		this.originRefrMedium = sessionEnvironment.originRefrMedium;
		this.originRefrSource = sessionEnvironment.originRefrSource;
		this.originRefrSocial = sessionEnvironment.originRefrSocial;
		this.env = sessionEnvironment.env;
	}

	get schema() {
		return 'iglu:com.justwatch/session_environment_context/jsonschema/1-1-0';
	}
}

export class SnowplowCMPContext extends SnowplowContext {
	__name = 'CMPContext' as const;
	constructor(public value: string) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/cmp_context/jsonschema/1-0-0';
	}
}

export class SnowplowFreemiumContext extends SnowplowContext {
	__name = 'FreemiumContext' as const;
	constructor(public isHideTitlesFiltered: boolean, public tier: number = 1) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/freemium_context/jsonschema/1-0-0';
	}
}

export class SnowplowPackageContext extends SnowplowContext {
	__name = 'PackageContext' as const;
	constructor(
		public packageId: number,
		public packageName: string,
		public parentType?: null | 'package' | 'bundle',
		public parentId?: number,
		public parentName?: string
	) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/package_context/jsonschema/1-0-0';
	}
}

// @TODO add logic which defines how the filterSettings are put into context
export class SnowplowFilterContext extends SnowplowContext {
	__name = 'FilterContext' as const;

	isProviderFiltered: boolean;
	providerId: string;
	isGenreFiltered: boolean;
	genreId: string;
	genreIdExcluded: string;
	isAgeRatingFiltered: boolean;
	ageRating: string | null;
	sortedBy: string | null;
	objectTypeFilter: number;
	isMonetizationTypeFiltered: boolean;
	monetizationType: string;
	isScoringFiltered: boolean;
	scoring: string;
	countryCode: string;
	languageCode: string;
	isMyStreamingServicesFiltered: boolean;
	searchString: string | null;
	releaseYearFrom: number | null;
	releaseYearUntil: number | null;
	minPriceCent: number | null;
	maxPriceCent: number | null;
	pageId: number | null;

	// Premium Filters
	isRuntimeFiltered: boolean;
	runtimeFrom: number | null;
	runtimeUntil: number | null;

	isImdbPopularityFiltered: boolean;
	imdbPopularityFrom?: number | null;
	imdbPopularityUntil?: number | null;

	isProductionCountryFiltered: boolean;
	productionCountries: string | null;
	productionCountriesExclude: string | null;

	constructor(
		filterState: FilterCollection,
		routeQuery: Route['query'],
		pageId: number,
		locale: string,
		providerShortNames: string[]
	) {
		super();

		const scoringValues = SCORINGS.filter(item => item.filterDisplay).reduce((root, item) => {
			root[item.provider] = { min_scoring_value: item.min, max_scoring_value: item.max };
			return root;
		}, {} as any);

		if (filterState) {
			// NON-NULLABLE
			this.isProviderFiltered = filterState.providers.length > 0;
			const selectedProviders = this.isProviderFiltered ? filterState['providers'] : providerShortNames;
			this.providerId = this.getFormattedArrayString(selectedProviders);

			this.isGenreFiltered = filterState.genres?.length > 0 || filterState.exclude_genres?.length > 0;
			this.genreId = this.getFormattedArrayString(filterState['genres']);
			this.genreIdExcluded = this.getFormattedArrayString(filterState['exclude_genres']);

			this.isAgeRatingFiltered = filterState.age_certifications?.length > 0;
			this.ageRating = this.getFormattedArrayString(filterState['age_certifications']);
			this.sortedBy = filterState.sort_by;

			if (filterState.content_types.indexOf(TitleObjectType.MOVIE) !== -1) {
				this.objectTypeFilter = 1;
			} else if (
				filterState.content_types.indexOf(TitleObjectType.SHOW) !== -1 ||
				filterState.content_types.indexOf(TitleObjectType.SEASON) !== -1
			) {
				this.objectTypeFilter = 2;
			} else {
				this.objectTypeFilter = 0;
			}
			this.isMonetizationTypeFiltered = filterState.monetization_types?.length > 0;
			this.monetizationType = this.getFormattedArrayString(filterState['monetization_types']);
			this.isScoringFiltered = Object.keys(filterState.scoring_filter_types || {}).length > 0;
			this.scoring = this.getStringifiedArrayValue(filterState, 'scoring_filter_types', scoringValues);
			const localeSplit = locale.split('_');
			this.languageCode = localeSplit[0].toLowerCase();
			this.countryCode = localeSplit[1].toLowerCase();
			// dummy value, as of 2020-12-15 this was only used in jw-tv and
			// did not make sense for jw-app as there's no such filter
			this.isMyStreamingServicesFiltered = false;
			// NULLABLE
			this.searchString = (routeQuery.q || '') as string;
			this.releaseYearFrom = filterState.release_year_from;
			this.releaseYearUntil = filterState.release_year_until;
			this.minPriceCent =
				filterState.min_price || filterState.min_price === 0 ? Math.round(filterState.min_price * 100) : null;
			this.maxPriceCent =
				filterState.max_price || filterState.max_price === 0 ? Math.round(filterState.max_price * 100) : null;
			this.pageId = pageId;

			//Premium Filters
			this.isRuntimeFiltered = !!(filterState.min_runtime || filterState.max_runtime);
			this.runtimeFrom = filterState.min_runtime;
			this.runtimeUntil = filterState.max_runtime;

			this.isImdbPopularityFiltered = !!filterState.scoring_filter_types?.[ScoringProvider.IMDB_VOTES];
			this.imdbPopularityFrom = filterState.scoring_filter_types?.[ScoringProvider.IMDB_VOTES]?.min_scoring_value;
			this.imdbPopularityUntil =
				filterState.scoring_filter_types?.[ScoringProvider.IMDB_VOTES]?.max_scoring_value;

			this.isProductionCountryFiltered =
				filterState.production_countries?.length > 0 || filterState.exclude_production_countries?.length > 0;
			this.productionCountries = this.getFormattedArrayString(filterState['production_countries']);
			this.productionCountriesExclude = this.getFormattedArrayString(filterState['exclude_production_countries']);
		}
	}

	private getStringifiedArrayValue(filterState: FilterCollection, key: keyof FilterCollection, fallback: string[]) {
		const value = ((filterState[key] as string[]) || []).length === 0 ? fallback : filterState[key];
		return JSON.stringify(value);
	}

	private getFormattedArrayString(filterValue: string[]) {
		return isEmptyArray(filterValue) ? '' : filterValue.join(', ');
	}

	get schema() {
		return 'iglu:com.justwatch/filter_context/jsonschema/1-1-4';
	}
}

export class LegacySnowplowPushContext extends SnowplowContext {
	__name = 'LegacyPushContext' as const;
	constructor(
		public pushCampaignId: number,
		public pushId: number,
		public pushRunId: number,
		public dispatchId: string,
		public localizedPlatformMessageId: number,
		public deeplinkTarget: string,
		public deviceLocale: string,
		public appLocale: string,
		public openedTstamp: string | null = null
	) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/push_context/jsonschema/1-0-0';
	}
}

export class SnowplowPushContext extends SnowplowContext {
	__name = 'PushContext' as const;
	constructor(
		public pushType: string,
		public pushTopic: string,
		public pushId: number,
		public pushRunId: number,
		public notificationId: string,
		public userId: string,
		public templateId: number,
		public deeplinkTarget: string,
		public deviceLocale: string,
		public appLocale: string,
		public sentTstamp: string | null
	) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/push_context/jsonschema/2-1-0';
	}
}

export class SnowplowClickoutContextGraphql extends SnowplowContext {
	__name = 'ClickoutContextGraphql' as const;
	provider: string | null; // clearName
	providerId: number | undefined;
	monetizationType: string;
	presentationType: string;
	currency: string | undefined;
	priceCent: number | undefined;
	originalPriceCent: number | undefined;
	audioLanguage: string | undefined;
	subtitleLanguage: string | null;
	showtime: string | undefined;
	partnerId: number | undefined;
	contentType: ClickoutContentType | undefined;
	filterOption: BuyBoxFilterOptions | undefined;
	rowNumber: number | undefined;
	deeplink: string | undefined;
	private deeplinkFallback: string | undefined;

	placement: ClickoutPlacement | undefined;
	clickoutType: ClickoutType | undefined;

	get _deeplinkFallback(): string | undefined {
		return this.deeplinkFallback;
	}

	// Wanna make sure the link is always encoded wherever it gets set
	set _deeplinkFallback(link: string | undefined) {
		this.deeplinkFallback = link ? encodeURI(link) : link;
	}

	static fromProviderOffer(
		offer: ClickoutOfferDetails,
		contentType?: SportObjectType | ClickoutContentType.LeavingSoon,
		filterOption?: BuyBoxFilterOptions,
		rowNumber?: number,
		placement?: ClickoutPlacement | undefined,
		clickoutType?: ClickoutType | undefined
	) {
		const instance = new this();

		instance.provider = offer.package.clearName;
		instance.monetizationType = offer.monetizationType ? toRESTMonetizationType(offer.monetizationType) : 'unknown';
		instance.presentationType = offer.presentationType ? toRESTPresentationType(offer.presentationType) : 'unknown';
		instance.providerId = offer.package.packageId;
		instance.partnerId = 1;
		instance.contentType = this.getContentType(contentType);
		instance.placement = placement;
		instance.clickoutType = clickoutType;

		instance.filterOption = filterOption;
		instance.rowNumber = rowNumber;

		if (offer.standardWebURL)
			instance._deeplinkFallback = offer?.preAffiliatedStandardWebURL || offer.standardWebURL;
		if (offer.retailPriceValue) instance.priceCent = Math.round(offer.retailPriceValue * 100);
		if (offer.currency) instance.currency = offer.currency;
		if (offer.lastChangeRetailPriceValue)
			instance.originalPriceCent = Math.round(offer.lastChangeRetailPriceValue * 100);

		return instance;
	}

	static getContentType(
		contentType?: SportObjectType | ClickoutContentType.LeavingSoon
	): ClickoutContentType | undefined {
		switch (contentType) {
			case SportObjectType.MainEvent:
				return ClickoutContentType.MainEvent;
			case SportObjectType.Highlight:
				return ClickoutContentType.Highlight;
			case SportObjectType.Interview:
				return ClickoutContentType.Interview;
			case ClickoutContentType.LeavingSoon:
				return ClickoutContentType.LeavingSoon;
			default:
				return undefined;
		}
	}

	get schema() {
		return 'iglu:com.justwatch/clickout_context/jsonschema/1-5-0';
	}
}

// Use clickout context 1-2-0 for affiliate links
export class SnowplowClickoutContextAffiliateLink extends SnowplowContext {
	__name = 'ClickoutContextAffiliateLink' as const;
	// provider offer clickout
	provider: string | null; // clearName
	providerId: number | undefined;
	monetizationType: string;
	presentationType: string;
	currency: string | undefined;
	price: number | undefined;
	originalPrice: number | undefined;
	// cinema clickout
	audioLanguage: string | undefined;
	subtitleLanguage: string | null;
	showtime: string | undefined;
	partnerId: number | undefined;

	static fromProviderOffer(offer: ClickoutOfferDetails) {
		const instance = new this();

		// @ts-ignore
		const bundleId: number | null = offer.bundleId ?? null;

		instance.provider = offer.package.clearName;
		instance.monetizationType = offer.monetizationType ? toRESTMonetizationType(offer.monetizationType) : 'unknown';
		instance.presentationType = offer.presentationType ? toRESTPresentationType(offer.presentationType) : 'unknown';
		instance.providerId = bundleId ?? offer.package.packageId;
		instance.partnerId = 1;

		if (offer.retailPriceValue) instance.price = offer.retailPriceValue;
		if (offer.currency) instance.currency = offer.currency;
		if (offer.lastChangeRetailPriceValue) instance.originalPrice = offer.lastChangeRetailPriceValue;

		return instance;
	}

	get schema() {
		return 'iglu:com.justwatch/clickout_context/jsonschema/1-2-0';
	}
}

export class SnowplowQuestionContext extends SnowplowContext {
	__name = 'QuestionContext' as const;
	constructor(
		public surveyId: number,
		public surveySession: string,
		public sequenceNumber: number,
		public nodeId: number,
		public elementId: number,
		public subtype: string,
		public translationId: number,
		public variationId: number,
		public locale: string, // 5 char locale e.g. de_DE
		public tagId?: number
	) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/question_context/jsonschema/1-0-0';
	}
}

export class SnowplowAnswerContext extends SnowplowContext {
	__name = 'AnswerContext' as const;
	constructor(
		public isSelected: boolean,
		public answerIndex: number,
		public nodeId: number,
		public elementId: number,
		public subtype: 'simple' | 'free_text',
		public translationId: number,
		public variationId: number,
		public textualAnswer?: string
	) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/answer_context/jsonschema/1-0-0';
	}
}

export class SnowplowIntentContext extends SnowplowContext {
	__name = 'IntentContext' as const;
	constructor(public id: number, public technicalName: string) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/intent_context/jsonschema/1-0-0';
	}
}

export class SnowplowModuleContext extends SnowplowContext {
	__name = 'ModuleContext' as const;
	constructor(
		public moduleId: string,
		public moduleIdx: number,
		public templateTechnicalName: string,
		public discoverySessionId: string,
		public anchorValue?: string
	) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/module_context/jsonschema/1-0-0';
	}
}

export class SnowplowImpressionContext extends SnowplowContext {
	__name = 'ImpressionContext' as const;
	titles: string;

	constructor(public length: number, public indexOffset: number, entityTitles: string[], public seenUntil?: number) {
		super();

		this.titles = JSON.stringify(entityTitles);
	}

	get schema() {
		return 'iglu:com.justwatch/impression_context/jsonschema/1-0-0';
	}
}

export class SnowplowImpressionContext2 extends SnowplowContext {
	__name = 'ImpressionContext2' as const;
	constructor(public titleCodes: string[], public arrayLength: number) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/impression_context/jsonschema/2-0-0';
	}
}

export class SnowplowPageTypeContext extends SnowplowContext {
	__name = 'PageTypeContext' as const;
	constructor(public pageType: string, public appLocale: string, public appLanguage: string) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/page_type_context/jsonschema/1-0-1';
	}
}

// CHANGE identifier VALUE IF NEEDED
export class SnowplowDevelopmentTestContext extends SnowplowContext {
	__name = 'DevelopmentTestContext' as const;
	constructor(public testEvent: boolean, public identifier: string = 'staging-environment') {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/development_test_context/jsonschema/1-0-0';
	}
}

/**
 * Snowplow experiment context
 */
export class SnowplowExperimentContext extends SnowplowContext {
	__name = 'ExperimentContext' as const;
	constructor(
		public experimentName: string,
		public version: string,
		public variant: string,
		public description: string = ''
	) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/experiment_context/jsonschema/1-0-0';
	}
}

export class SnowplowSportContext extends SnowplowContext {
	__name = 'SportContext' as const;
	sportTechName: string;
	// https://gitlab.justwatch.com/jw/jw-dwh/-/blob/master/snowplow-schemas/iglu-schemas/schemas/com.justwatch/sport_context/jsonschema/1-0-1#L17
	objectType: 'sport' | 'competition' | 'season' | 'event' | 'venue' | 'competitor' | 'generic';
	competitionId: number | undefined;
	seasonId: number | undefined;
	eventId: number | undefined;
	event:
		| {
				status: string;
				matchStatus?: string;
				startAt?: string;
				endAt?: string | null;
				winnerId?: string | undefined; // nice to have
		  }
		| undefined;
	venueId: number | undefined; // nice to have
	competitorIdHome: string | undefined; // nice to have
	competitorIdGuest: string | undefined; // nice to have
	competitorIdWinner: string | undefined; // nice to have

	// competitors: Competitor[]; // future
	constructor(sportTechName: SnowplowSportContext['sportTechName'], rawObjectType: ObjectType | SportPageType) {
		super();
		switch (rawObjectType) {
			case ObjectType.SportCompetitor:
				this.objectType = 'competitor';
				break;
			case SportPageType.SportCompetition:
			case ObjectType.SportCompetition:
				this.objectType = 'competition';
				break;
			case SportPageType.SportEvent:
			case ObjectType.SportEvent:
				this.objectType = 'event';
				break;
			case SportPageType.SportOverview:
			case ObjectType.Sport:
				this.objectType = 'sport';
				break;
			case SportPageType.AllSports:
			default:
				this.objectType = 'generic';
				break;
		}

		this.sportTechName = sportTechName;
	}

	get schema() {
		return 'iglu:com.justwatch/sport_context/jsonschema/1-0-1';
	}
	static getEventContent(event: SingleStepSportEvent | MultiStepSportEvent) {
		return 'parent' in event
			? (event as MultiStepSportEvent).parent!.content
			: (event as SingleStepSportEvent).content;
	}
	static fromSportEventV2(
		sportTechName: SportType,
		objectType: ObjectType | SportPageType,
		event: SingleStepSportEvent | MultiStepSportEvent
	) {
		const sportContext: SnowplowSportContext | null = new SnowplowSportContext(sportTechName, objectType);
		const eventContent = SnowplowSportContext.getEventContent(event);
		sportContext.competitionId = event.competition?.objectId;
		sportContext.seasonId = event.competition?.activeSeason?.seasonID;
		sportContext.event = {
			status: eventContent.status,
			startAt: eventContent?.startAt,
			endAt: eventContent?.endAt,
			matchStatus: eventContent?.status,
		};
		sportContext.eventId = event.objectId;
		sportContext.venueId = eventContent?.venue?.venueID || undefined; // to prevent sending venueID = 0

		if ('competitors' in event) {
			sportContext.competitorIdHome = event.competitors?.homeTeam?.competitors.map(c => c.objectId).join(', ');
			sportContext.competitorIdGuest = event.competitors?.awayTeam?.competitors.map(c => c.objectId).join(', ');

			if (event.competitors.homeTeam.winner) {
				sportContext.event.winnerId = sportContext.competitorIdHome;
				sportContext.competitorIdWinner = sportContext.competitorIdHome;
			}

			if (event.competitors.awayTeam.winner) {
				sportContext.event.winnerId = sportContext.competitorIdGuest;
				sportContext.competitorIdWinner = sportContext.competitorIdGuest;
			}
		}

		return sportContext;
	}
}

export class SnowplowCoreWebVitalsContext extends SnowplowContext {
	__name = 'CoreWebVitalsContext' as const;
	metrics: readonly {
		name: 'CLS' | 'FCP' | 'FID' | 'INP' | 'LCP' | 'TTFB';
		id: string;
		delta: number;
		value: number;
	}[];

	constructor(data: Set<Metric>, public flags: Record<string, boolean> | null = null) {
		super();

		this.metrics = Object.freeze(
			Array.from(data).map(({ id, name, value, delta }) => ({
				id,
				name,
				value: Math.round(value * 100) / 100,
				delta: Math.round(name === 'CLS' ? delta * 1000 : delta),
			}))
		);
	}

	get schema() {
		return 'iglu:com.justwatch/core_web_vitals_context/jsonschema/1-0-0';
	}
}

export class SnowplowAudienceContext extends SnowplowContext {
	__name = 'AudienceContext' as const;

	constructor(
		public type: string,
		public objectUuid?: string | null,
		public objectId?: string | null,
		// subType can take type string, but for now we are only using it to determine if PA is exisiting (old) or new (fresh)
		public subType?: 'old' | 'fresh' | null
	) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/audience_context/jsonschema/1-0-2';
	}
}

export class SnowplowRecommenderContext extends SnowplowContext {
	__name = 'RecommenderContext' as const;

	constructor(
		public source: string,
		public source_title: string | null = null,
		public source_season_number: number | null = null,
		public influenced: boolean | null = null,
		public composition_id: string | null = null
	) {
		super();
	}

	get schema() {
		return 'iglu:com.justwatch/recommender_context/jsonschema/1-1-1';
	}
}

/** The unique string names for each Snowplow Context. */
export type SnowplowContextName = keyof typeof CONTEXT_CLASSES;

/** All Snowplow contexts and their name. */
export type ContextClasses = {
	[K in SnowplowContextName]: InstanceType<(typeof CONTEXT_CLASSES)[K]>;
};

/** Mapping of Snowplow context names and their Class. */
export const CONTEXT_CLASSES = {
	TitleContext: SnowplowTitleContext,
	TitleContextGraphql: SnowplowTitleContextGraphql,
	PersonContext: SnowplowPersonContext,
	CampaignContextV1: SnowplowCampaignContextV1,
	CampaignContextV2: SnowplowCampaignContextV2,
	GeoContext: SnowplowGeoContext,
	LoginContext: SnowplowLoginContext,
	CMPContext: SnowplowCMPContext,
	FreemiumContext: SnowplowFreemiumContext,
	PackageContext: SnowplowPackageContext,
	FilterContext: SnowplowFilterContext,
	LegacyPushContext: LegacySnowplowPushContext,
	PushContext: SnowplowPushContext,
	ClickoutContextGraphql: SnowplowClickoutContextGraphql,
	ClickoutContextAffiliateLink: SnowplowClickoutContextAffiliateLink,
	QuestionContext: SnowplowQuestionContext,
	AnswerContext: SnowplowAnswerContext,
	IntentContext: SnowplowIntentContext,
	ModuleContext: SnowplowModuleContext,
	ImpressionContext: SnowplowImpressionContext,
	ImpressionContext2: SnowplowImpressionContext2,
	PageTypeContext: SnowplowPageTypeContext,
	DevelopmentTestContext: SnowplowDevelopmentTestContext,
	ExperimentContext: SnowplowExperimentContext,
	SportContext: SnowplowSportContext,
	CoreWebVitalsContext: SnowplowCoreWebVitalsContext,
	AudienceContext: SnowplowAudienceContext,
	RecommenderContext: SnowplowRecommenderContext,
	HashedLoggedInUserContext: SnowplowHashedLoggedInUserContext,
	SessionEnvironmentContext: SnowplowSessionEnvironmentContext,
};
