import Vue from 'vue';
import { ActionTree, MutationTree, Module } from 'vuex';
import { GettersConfig } from '@/interfaces/store';

export type QueueType = 'pageViews' | 'events' | 'consents';
type SessionEnvironmentOrigin = 'seo' | 'organic' | 'social' | 'search' | 'direct' | 'redirect';

export type SessionEnvironment = {
	sessionReady: boolean;
	origin: SessionEnvironmentOrigin | null;
	hasAdBlocker: boolean | null;
	originPageUrl: string | null;
	originPageReferred: string | null;
	originRefrMedium: string | null;
	originRefrSource: string | null;
	originRefrSocial: string | null;
	env: string | null;
};

export type TrackingQueues = Record<QueueType, any[]>;

export type TrackingState = {
	pagesVisited: number;
	queues: TrackingQueues;
	sessionEnvironment: SessionEnvironment;
};
export type TrackingGetters = {
	getQueueOf: (queueType: QueueType) => any[];
};

export type TrackingGettersConfig = GettersConfig<TrackingState, TrackingGetters>;

export type TrackingStore = {
	state: TrackingState;
	getters: TrackingGettersConfig;
};

// STATE
export const getInitialTrackingState = (): TrackingState => ({
	pagesVisited: 0,
	queues: {
		pageViews: [],
		events: [],
		consents: [],
	} as unknown as TrackingQueues,
	sessionEnvironment: {
		sessionReady: false,
		origin: null,
		env: null,
		hasAdBlocker: null,
		originPageUrl: null,
		originPageReferred: null,
		originRefrMedium: null,
		originRefrSource: null,
		originRefrSocial: null,
	},
});

// GETTERS
const getters: TrackingGettersConfig = {
	getQueueOf: state => (queueType: QueueType) => {
		return state.queues[queueType];
	},
};

// ACTIONS
const actions: ActionTree<TrackingState, any> = {
	setQueues: async ({ commit }, queues) => {
		commit('SET_QUEUES', queues);
	},

	resetQueue: async ({ commit }, queueType: QueueType) => {
		commit('RESET_QUEUE', queueType);
	},

	addToQueue: async ({ commit }, { queueType, event }) => {
		commit('ADD_TO_QUEUES', { queueType, event });
	},

	addPageView: async ({ dispatch }, event) => {
		const queueType = 'pageViews';
		dispatch('addToQueue', { queueType, event });
	},

	addTrackEvent: async ({ dispatch }, event) => {
		const queueType = 'events';
		dispatch('addToQueue', { queueType, event });
	},

	setSessionEnvironment: async ({ commit }, sessionEnvironment: Partial<SessionEnvironment>) => {
		commit('SET_SESSION_ENVIRONMENT', sessionEnvironment);
	},

	setSessionEnvironmentByKey: async ({ commit }, { key, value }) => {
		commit('SET_SESSION_ENVIRONMENT_KEY', { key, value });
	},

	updateQueuedEventsContext: async ({ commit }, { queueType, context }) => {
		commit('ADD_CONTEXT_TO_QUEUE', { queueType, context });
	},
};

// MUTATIONS
const mutations: MutationTree<TrackingState> = {
	RESET_QUEUE: (state, queueType: QueueType) => {
		Vue.set(state.queues, `${queueType}`, []);
	},

	SET_QUEUES: (state, queues: TrackingQueues) => {
		state.queues = queues;
	},

	ADD_TO_QUEUES: (state, { queueType, event }: { queueType: QueueType; event: any }) => {
		state.queues[queueType].push(event);
	},

	INCREASE_PAGES_VISITED: state => {
		state.pagesVisited = state.pagesVisited + 1;
	},

	SET_SESSION_ENVIRONMENT: (state, sessionEnvironment: Partial<SessionEnvironment>) => {
		state.sessionEnvironment = { ...state.sessionEnvironment, ...sessionEnvironment };
	},

	SET_SESSION_ENVIRONMENT_KEY: <S extends keyof SessionEnvironment, V extends SessionEnvironment[S]>(
		state: TrackingState,
		{ key, value }: { key: S; value: V }
	) => {
		state.sessionEnvironment[key] = value;
	},
};

export default {
	namespaced: true,
	state: getInitialTrackingState,
	getters,
	actions,
	mutations,
} as Module<TrackingState, any>;
